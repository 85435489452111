export const cookies = {
  facebook: "gatsby-gdpr-facebook-pixel",
  googleAnalytics: "gatsby-gdpr-google-analytics",
  googleTagManager: "gatsby-gdpr-google-tagmanager",
};

export const setCookie = (cookieName, cookieValue, expireDayCount = 365) => {
  var d = new Date();
  d.setTime(d.getTime() + expireDayCount * 24 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${cookieValue};expires=${d.toUTCString()}`;
};

export const getCookie = (cookieName) => {
  var name = cookieName + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      const value = c.substring(name.length, c.length);
      if (value === "true") return true;
      else if (value === "false") return false;
      else return null;
    }
  }
  return null;
};

export const checkCookieIsSet = () => getCookie("cookiesSet") === true;
