import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "./Layout.module.scss";
import Cookie from "../Cookie/Cookie";

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // data.site.siteMetadata.title
  return (
    <>
      <div>
        <main>
          {className ? <div className={className}>{children}</div> : children}
        </main>
      </div>
      <Cookie />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
