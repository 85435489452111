import React from "react";
import styles from "./Typography.module.scss";
import { cn } from "../../lib/helpers";

const withTypographyProps = (Component) => (props) => {
  const { className, align } = props;
  return (
    <Component
      {...props}
      className={
        className && align ? cn(className, `align-${align}`) : className
      }
    />
  );
};

const Headline1 = withTypographyProps((props) => (
  <h1 {...props} className={cn(styles.Headline1, props.className)}>
    {props.children}
  </h1>
));
const Headline2 = withTypographyProps((props) => (
  <h2 {...props} className={cn(styles.Headline2, props.className)}>
    {props.children}
  </h2>
));
const Headline3 = withTypographyProps((props) => (
  <h3 {...props} className={cn(styles.Headline3, props.className)}>
    {props.children}
  </h3>
));
const Headline4 = withTypographyProps((props) => (
  <h4 {...props} className={cn(styles.Headline4, props.className)}>
    {props.children}
  </h4>
));
const Headline5 = withTypographyProps((props) => (
  <h5 {...props} className={cn(styles.Headline5, props.className)}>
    {props.children}
  </h5>
));
const Headline6 = withTypographyProps((props) => (
  <h6 {...props} className={cn(styles.Headline6, props.className)}>
    {props.children}
  </h6>
));
const Subtitle1 = withTypographyProps((props) => (
  <h5 {...props} className={cn(styles.Subtitle1, props.className)}>
    {props.children}
  </h5>
));
const Subtitle2 = withTypographyProps((props) => (
  <h6 {...props} className={cn(styles.Subtitle2, props.className)}>
    {props.children}
  </h6>
));
const Body1 = withTypographyProps((props) => (
  <p {...props} className={cn(styles.Body1, props.className)}>
    {props.children}
  </p>
));
const Body2 = withTypographyProps((props) => (
  <p {...props} className={cn(styles.Body2, props.className)}>
    {props.children}
  </p>
));
const Caption = withTypographyProps((props) => (
  <p {...props} className={cn(styles.Caption, props.className)}>
    {props.children}
  </p>
));

export {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption,
};
