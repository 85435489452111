import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Cookie.module.scss";
import { setCookie, checkCookieIsSet, getCookie, cookies } from "./cookieUtils";
import Toggle from "../Toggle/Toggle";
import Button from "../Button/Button";
import { Body1, Body2 } from "../Typography/Typography";
import { cn } from "../../lib/helpers";

const Cookie = ({}) => {
  const [open, setOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  useEffect(() => {
    !checkCookieIsSet() && setOpen(true);
  }, []);

  const confirm = () => {
    setCookie("cookiesSet", true);
    Object.values(cookies).forEach(
      (cookie) => getCookie(cookie) === null && setCookie(cookie, true)
    );
    setOpen(false);
    if (typeof window !== "undefined") {
      // window isn't defined during build
      window.location.reload();
    }
  };
  const initialValue = (cookie) =>
    getCookie(cookie) === null ? true : getCookie(cookie);
  return open ? (
    <div className={styles.cookieContainer}>
      <Body2>
        <strong>
          Vi bruker informasjonskapsler (cookies) og dine data til å forbedre og
          tilpasse tjenestene og tilbudene du ser og bruker.
        </strong>
      </Body2>
      <Body2
        className={cn(styles.settingsToggle, openSettings ? styles.open : "")}
        onClick={() => setOpenSettings(!openSettings)}
      >
        Justér innstillingene
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
        >
          <path d="M5 8l4 4 4-4z" />
          <path d="M0 0h18v18H0z" fill="none" />
        </svg>
      </Body2>
      <div className={cn(styles.settings, openSettings ? styles.open : "")}>
        <Toggle
          label="Facebook Pixel"
          toggle={(bool) => setCookie(cookies.facebook, bool)}
          initialValue={initialValue(cookies.facebook)}
        />
        <Toggle
          label="Google Analytics"
          toggle={(bool) => setCookie(cookies.googleAnalytics, bool)}
          initialValue={initialValue(cookies.googleAnalytics)}
        />
        <Toggle
          label="Google Tag Manager"
          toggle={(bool) => setCookie(cookies.googleTagManager, bool)}
          initialValue={initialValue(cookies.googleTagManager)}
        />
      </div>
      <Button secondary onClick={confirm}>
        Godta
      </Button>
    </div>
  ) : (
    ""
  );
};

export default Cookie;
