import React from "react";
import styles from "./Button.module.scss";
import { Link } from "gatsby";
import { cn } from "../../lib/helpers";
import { Subtitle1 } from "../Typography/Typography";

const Button = ({
  children,
  type,
  onClick,
  url,
  primary,
  secondary,
  disabled,
}) => {
  const buttonClass = cn(
    styles.button,
    primary && styles.primary,
    secondary && styles.secondary
  );
  return url ? (
    <Link className={buttonClass} to={url} disabled={disabled}>
      <Subtitle1>{children}</Subtitle1>
    </Link>
  ) : (
    <button
      onClick={onClick}
      type={type}
      className={buttonClass}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
