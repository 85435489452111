import React from "react";
import styles from "./Toggle.module.scss";
import { Body2 } from "../Typography/Typography";

const Toggle = ({ id, label, toggle, initialValue }) => {
  const onChange = (e) => {
    toggle && toggle(e.target.checked);
  };
  return (
    <label className={styles.toggle}>
      <Body2 className={styles.toggleLabel}>{label}</Body2>
      <input
        className={styles.toggleCheckbox}
        type="checkbox"
        onChange={onChange}
        defaultChecked={initialValue}
      />
      <div className={styles.toggleSwitch} />
    </label>
  );
};

export default Toggle;
